<template>
  <div class="tips__container">
    <aside class="tips__side" :class="{'tips__side--show': show_sidebar}">
      <nav class="tips__nav">
        <div class="tips__side-title">
          <img src="@/assets/img/tips/tips-list.svg" alt="List">
          <span>Quick navigate</span>
        </div>
        <ul class="tips__nav-list">
          <li>
            <a @click.prevent="onScrollToSection('section-1')" href="#section-1" class="tips__nav-link tips__nav-link--active">
              <div class="tips__nav-icon">
                <img src="@/assets/img/tips/tips-personal-icon.svg" alt="Icon">
              </div>
              Your Personal Trip Board
            </a>
          </li>
          <li>
            <a @click.prevent="onScrollToSection('section-2')" href="#section-2" class="tips__nav-link">
              <div class="tips__nav-icon">
                <img src="@/assets/img/tips/tips-gear-icon.svg" alt="Icon">
              </div>
              Gear Required For The Hike
            </a>
          </li>
          <li>
            <a @click.prevent="onScrollToSection('section-3')" href="#section-3" class="tips__nav-link">
              <div class="tips__nav-icon">
                <img src="@/assets/img/tips/tips-packing-icon.svg" alt="Icon">
              </div>
              Packing your gear for the hike
            </a>
          </li>
          <li>
            <a @click.prevent="onScrollToSection('section-4')" href="#section-4" class="tips__nav-link">
              <div class="tips__nav-icon">
                <img src="@/assets/img/tips/tips-clock-icon.svg" alt="Icon">
              </div>
              When does the trip start?
            </a>
          </li>
          <li>
            <a @click.prevent="onScrollToSection('section-5')" href="#section-5" class="tips__nav-link">
              <div class="tips__nav-icon">
                <img src="@/assets/img/tips/tips-luggage-icon.svg" alt="Icon">
              </div>
              Luggage & valuables storage
            </a>
          </li>
          <li>
            <a @click.prevent="onScrollToSection('section-6')" href="#section-6" class="tips__nav-link">
              <div class="tips__nav-icon">
                <img src="@/assets/img/tips/tips-support-icon.svg" alt="Icon">
              </div>
              Your Kilimanjaro Team
            </a>
          </li>
          <li>
            <a @click.prevent="onScrollToSection('section-7')" href="#section-7" class="tips__nav-link">
              <div class="tips__nav-icon">
                <img src="@/assets/img/tips/tips-gps-icon.svg" alt="Icon">
              </div>
              GPS Tracking
            </a>
          </li>
          <li>
            <a @click.prevent="onScrollToSection('section-8')" href="#section-8" class="tips__nav-link">
              <div class="tips__nav-icon">
                <img src="@/assets/img/tips/tips-water-icon.svg" alt="Icon">
              </div>
              Stay hydrated
            </a>
          </li>
          <li>
            <a @click.prevent="onScrollToSection('section-9')" href="#section-9" class="tips__nav-link">
              <div class="tips__nav-icon">
                <img src="@/assets/img/tips/tips-medical-icon.svg" alt="Icon">
              </div>
              Medical Check-Ups
            </a>
          </li>
          <li>
            <a @click.prevent="onScrollToSection('section-10')" href="#section-10" class="tips__nav-link">
              <div class="tips__nav-icon">
                <img src="@/assets/img/tips/tips-device-icon.svg" alt="Icon">
              </div>
              Electronic Devices
            </a>
          </li>
          <li>
            <a @click.prevent="onScrollToSection('section-11')" href="#section-11" class="tips__nav-link">
              <div class="tips__nav-icon">
                <img src="@/assets/img/tips/tips-shower-icon.svg" alt="Icon">
              </div>
              Toilet and Shower Facilities
            </a>
          </li>
          <li>
            <a @click.prevent="onScrollToSection('section-12')" href="#section-12" class="tips__nav-link">
              <div class="tips__nav-icon">
                <img src="@/assets/img/tips/tips-responsible-icon.svg" alt="Icon">
              </div>
              Responsible Travel
            </a>
          </li>
          <li>
            <a @click.prevent="onScrollToSection('section-13')" href="#section-13" class="tips__nav-link">
              <div class="tips__nav-icon">
                <img src="@/assets/img/tips/tips-beginning-icon.svg" alt="Icon">
              </div>
              Beginning your trek
            </a>
          </li>
          <li>
            <a @click.prevent="onScrollToSection('section-14')" href="#section-14" class="tips__nav-link">
              <div class="tips__nav-icon">
                <img src="@/assets/img/tips/tips-mountain-icon.svg" alt="Icon">
              </div>
              How Will You Spend Your Days On Kilimanjaro
            </a>
          </li>
          <li>
            <a @click.prevent="onScrollToSection('section-15')" href="#section-15" class="tips__nav-link">
              <div class="tips__nav-icon">
                <img src="@/assets/img/tips/tips-food-icon.svg" alt="Icon">
              </div>
              Food On The Hike
            </a>
          </li>
          <li>
            <a @click.prevent="onScrollToSection('section-16')" href="#section-16" class="tips__nav-link">
              <div class="tips__nav-icon">
                <img src="@/assets/img/tips/tips-rest-icon.svg" alt="Icon">
              </div>
              When You Rest In The Camp
            </a>
          </li>
          <li>
            <a @click.prevent="onScrollToSection('section-17')" href="#section-17" class="tips__nav-link">
              <div class="tips__nav-icon">
                <img src="@/assets/img/tips/tips-socks-icon.svg" alt="Icon">
              </div>
              Keep Warm On Kilimanjaro
            </a>
          </li>
          <li>
            <a @click.prevent="onScrollToSection('section-18')" href="#section-18" class="tips__nav-link">
              <div class="tips__nav-icon">
                <img src="@/assets/img/tips/tips-night-icon.svg" alt="Icon">
              </div>
              The Summit Night
            </a>
          </li>
          <li>
            <a @click.prevent="onScrollToSection('section-19')" href="#section-19" class="tips__nav-link">
              <div class="tips__nav-icon">
                <img src="@/assets/img/tips/tips-descent-icon.svg" alt="Icon">
              </div>
              Descent From Kilimanjaro
            </a>
          </li>
          <li>
            <a @click.prevent="onScrollToSection('section-20')" href="#section-20" class="tips__nav-link">
              <div class="tips__nav-icon">
                <img src="@/assets/img/tips/tips-insurance-icon.svg" alt="Icon">
              </div>
              Evacuation And Insurance
            </a>
          </li>
        </ul>
      </nav>
    </aside>
    <div class="tips__content">
      <div class="tips__head">
        <div class="tips__head-container">
          <h1 class="tips__title tips__title--mb">Essential info about Kilimanjaro expeditions</h1>
          <span>Summary of everything you should know before arrival </span>
        </div>
        <button @click="onShowSidebar" class="tips__burger"></button>
      </div>
      <div class="tips__wish">
        Your Kilimanjaro adventure will begin soon. Below, we’ve summarized the essentials about the trip to the Roof of Africa
        <img src="@/assets/img/tips/tips-sun.svg" alt="Sun">
      </div>
      <p class="tips__text">Reading this briefing will take about 30-35 minutes.</p>
      <p class="tips__text">If you're short on time, you can watch the video below, which highlights the key aspects of your expedition. However, we still recommend reading this guide before you pack for your flight, as it is much more detailed than the video.</p>
      <div class="tips__video">
        <iframe
          srcdoc="<style>
            * {
              padding: 0;
              margin: 0;
              overflow: hidden;
            }

            body, html {
              height: 100%;
            }

            a {
              display: flex;
              height: 100%;
            }

            img {
              position: absolute;
              width: 100%;
              height: 100%;
              inset: 0;
            }

            svg {
              margin: auto;
              filter: drop-shadow(1px 1px 10px hsl(206.5, 70.7%, 8%));
              transition: all 0.25s ease-in-out;
            }

            body:hover svg {
              filter: drop-shadow(1px 1px 10px hsl(206.5, 0%, 10%));
              transform: scale(1.2);
            }
          </style>
          <a href='https://www.youtube.com/embed/_MRlLpSwAro?si=dgdAO4C4Dau4evXy&autoplay=1&mute=1'>
            <img src='https://img.youtube.com/vi/_MRlLpSwAro/maxresdefault.jpg' alt='Preview'>
            <svg xmlns='http://www.w3.org/2000/svg' width='80' height='59' fill='none'><path fill='#fff' d='M21.332 8h36v42.667h-36z'/><path fill='#E60000' d='M78.13 9.985c-1.6-4.923-6.895-7.905-11.767-8.589a249.56 249.56 0 0 0-52.725 0C8.765 2.08 3.456 5.033 1.87 9.985a99.938 99.938 0 0 0 0 38.493c1.6 4.916 6.894 7.905 11.767 8.589a249.568 249.568 0 0 0 52.724 0c4.873-.684 10.182-3.637 11.767-8.59a99.937 99.937 0 0 0 0-38.492Zm-47.7 34.573v-30.66l24.958 15.33c-8.414 5.17-16.58 10.181-24.958 15.33Z'/></svg>
          </a>
        "
          class="tips__iframe"
          loading="lazy"
          src="https://www.youtube.com/embed/_MRlLpSwAro?si=dgdAO4C4Dau4evXy"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </div>
      <section class="tips__section" id="section-1">
        <div class="tips__headline">
          <div class="tips__headline-picture">
            <img src="@/assets/img/tips/tips-personal.svg" alt="Personal">
          </div>
          <h2 class="tips__title">Your Personal Trip Board</h2>
        </div>
        <p class="tips__text">
          Our Tanzania team needs information from your Personal Trip Board to organize your airport pick-up,
          process Mt Kilimanjaro entry permits, and arrange a suitable meal plan. There, you will also complete a medical
          form to inform us of any health issues we should consider while planning your trip.
        </p>
        <div class="tips__text">
          Ideally, we would like you to complete it 1-2 weeks before arrival. If you have any questions about the Board,
          please ask your travel consultant.
        </div>
      </section>
      <section class="tips__section" id="section-2">
        <div class="tips__headline">
          <div class="tips__headline-picture">
            <img src="@/assets/img/tips/tips-gear.svg" alt="Gear">
          </div>
          <h2 class="tips__title">Gear required for the hike</h2>
        </div>
        <div class="tips__gear">
          <div class="tips__gear-img"></div>
          <div class="tips__gear-content">
            <span class="tips__gear-title">Get PDF Kilimanjaro Packing List</span>
            <span class="tips__gear-text">For the complete list of the required gear and detailed instructions on packing refer to our Kilimanjaro Packing List guide</span>
            <a href="https://altezza.travel/en/kilimanjaro-packing-list.pdf" target="_blank" class="tips__gear-link">
              GET PDF
            </a>
          </div>
        </div>
        <p class="tips__text">
          Our Tanzania team consultant will check your equipment when you arrive in Tanzania to ensure it is suitable for
          the Kilimanjaro expedition.
        </p>
        <p class="tips__text">
          Some items, such as sleeping bags, down jackets, and trekking poles, are bulky to bring and costly to buy.
          You may hire them from us on the spot. Altezza has the largest stock of mountain gear and clothing in Kilimanjaro.
          There you may get gear from RedFox, Black Diamond, Mountain Hardwear, Marmot, Zamberlan, and other quality brands.
        </p>
        <p class="tips__text">
          If you plan to hire gear from Altezza, tick the items you need on the Personal Trip Board. We’ll have everything
          ready by the time you arrive in Kilimanjaro, and your climb coordinators in Tanzania will bring the gear to the
          orientation briefing.
        </p>
      </section>
      <section class="tips__section" id="section-3">
        <div class="tips__headline">
          <div class="tips__headline-picture">
            <img src="@/assets/img/tips/tips-packing.svg" alt="Packing">
          </div>
          <h2 class="tips__title">Packing your gear for the hike</h2>
        </div>
        <p class="tips__text">When packing your Kilimanjaro gear, there are two things you should keep in mind:</p>
        <h3 class="tips__title">What to put in the backpack?</h3>
        <p class="tips__text">
          <b>Put everything that needs to be immediately available on the trek in the backpack.</b> You will carry it
          yourself, and in case you need anything, it will be within reach.
        </p>
        <p class="tips__text">
          Make sure that your daypack contains the following items: a water bottle, fleece jacket, membrane jacket and
          pants, sunglasses, sunscreen, sun hat, lip balm, headlamp, trekking poles, leg gaiters, wet wipes, neck gaiter,
          and reusable bags for trash disposal. For details, please download our
          <a href="https://altezza.travel/en/kilimanjaro-packing-list.pdf" target="_blank">Kilimanjaro Packing List</a>.
        </p>
        <p class="tips__text">
          You may attach trekking poles to the outside of the backpack for the ride from our climbing base to the trailhead.
          After the trek begins, you'll use them daily, and they'll no longer need to be packed.
        </p>
        <p class="tips__text">
          When choosing a backpack for this trip, make sure that the size is 30-40 liters, and it has waist straps and
          rain cover for bad weather. You may hire a backpack from us if you don't have a suitable one.
        </p>
        <p class="tips__text">
          The porters move much faster than the travelers, reaching the camp before the main team to prepare everything
          for your arrival. It means you can only access your duffle bag (see below) in the camp. Keep this in mind when
          packing your daypack in the morning; include anything you think you'll need during the day.
        </p>
        <p class="tips__text">
          However, be mindful not to overpack your daypack, as you'll have to carry it for 5-6 hours daily. Don't hesitate
          to inform your guide if you've overestimated what you can comfortably carry. They can help by carrying your
          backpack or taking some items off your hands to lighten your load.
        </p>
        <div class="tips__note">
          <div class="tips__note-title">Additional porter for the backpack</div>
          <p class="tips__note-text">
            You may hire a personal porter to carry your daypack. He'll be hiking alongside you throughout the journey,
            and you'll enjoy Kilimanjaro without any load.
          </p>
          <p class="tips__note-text">
            We recommend this option to hikers with back problems or those not confident in their fitness level. If you
            feel you might need a personal porter - contact your manager.
          </p>
          <p class="tips__note-text">
            Please note that extra charges will be applied for this option. As a member of the Kilimanjaro Porters
            Assistance Project (KPAP), Altezza Travel adheres to strict porter welfare policies, which include paying
            above-market salaries and fostering a healthy work environment. You can read more about the
            <a href="https://altezza.travel/en/articles/about-kpap" target="_blank">collaboration between Altezza and KPAP</a>.
          </p>
        </div>
        <p class="tips__text" style="text-align: center">
          <b>Also, check out this video to learn more about our porters and what our KPAP status means for them:</b>
        </p>
        <div class="tips__video">
          <iframe
            srcdoc="<style>
            * {
              padding: 0;
              margin: 0;
              overflow: hidden;
            }

            body, html {
              height: 100%;
            }

            a {
              display: flex;
              height: 100%;
            }

            img {
              position: absolute;
              width: 100%;
              height: 100%;
              inset: 0;
            }

            svg {
              margin: auto;
              filter: drop-shadow(1px 1px 10px hsl(206.5, 70.7%, 8%));
              transition: all 0.25s ease-in-out;
            }

            body:hover svg {
              filter: drop-shadow(1px 1px 10px hsl(206.5, 0%, 10%));
              transform: scale(1.2);
            }
          </style>
          <a href='https://www.youtube.com/embed/eonFDGI8LkM?si=5e80pnse3AyH8zmG&autoplay=1&mute=1'>
            <img src='https://img.youtube.com/vi/eonFDGI8LkM/maxresdefault.jpg' alt='Preview'>
            <svg xmlns='http://www.w3.org/2000/svg' width='80' height='59' fill='none'><path fill='#fff' d='M21.332 8h36v42.667h-36z'/><path fill='#E60000' d='M78.13 9.985c-1.6-4.923-6.895-7.905-11.767-8.589a249.56 249.56 0 0 0-52.725 0C8.765 2.08 3.456 5.033 1.87 9.985a99.938 99.938 0 0 0 0 38.493c1.6 4.916 6.894 7.905 11.767 8.589a249.568 249.568 0 0 0 52.724 0c4.873-.684 10.182-3.637 11.767-8.59a99.937 99.937 0 0 0 0-38.492Zm-47.7 34.573v-30.66l24.958 15.33c-8.414 5.17-16.58 10.181-24.958 15.33Z'/></svg>
          </a>
        "
            class="tips__iframe"
            loading="lazy"
            src="https://www.youtube.com/embed/_MRlLpSwAro?si=5e80pnse3AyH8zmG"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        <h3 class="tips__title">What to pack in the duffle bag?</h3>
        <p class="tips__text">
          <b>Use the duffle bag for the gear you wouldn’t need during the day.</b> Most heavy gear for the summit part
          and the sleeping bag will go to the duffle bag carried by our porter. The duffle bag should be 100-120 liters
          and made of sturdy, waterproof material. You may hire a suitable one from us on arrival. We have duffle bags
          tailored specifically for Kilimanjaro expeditions. These bags are produced by a reputable manufacturer
          exclusively for our company.
        </p>
        <p class="tips__text">
          Ensure your duffle bag gear includes a sleeping bag rated for -12°C/10°F, taking into account its bulkiness.
          Additionally, pack a sleeping bag liner if needed, summit jacket and trousers, a light down jacket, mittens,
          fleece hat and gloves, neck gaiter, extra t-shirts and socks, hiking boots, 3 sets of thermal underwear, power
          bank, towel, personal medication, toothpaste and toothbrush, and hand warmers.
          <b>For more details, please download our <a href="https://altezza.travel/en/kilimanjaro-packing-list.pdf" target="_blank">Kilimanjaro Packing List</a>.</b>
        </p>
        <p class="tips__text"><b>Optional items:</b></p>
        <p class="tips__text">
          Consider bringing earplugs, hand moisturizer, an inflatable pillow, an extra fleece jacket, energy snacks,
          a 1L thermos for drinks, and, optionally, a urinal bottle.
        </p>
        <p class="tips__text">
          Waterproof bags are convenient for packing your gear. Besides keeping your clothes safe from sudden rains or
          accidental leaks, it helps you organize your gear in a duffel bag or a backpack. You can use a few dry bags
          to sort your clothes into different categories under name tags (e.g., “summit clothes’, “underwear”, “fleece
          jackets”, “toiletry”, etc.) This way, finding the necessary things when you arrive at the camp and start
          unpacking won't take long.
        </p>
        <div class="tips__note">
          <div class="tips__note-title">The weight limit of a duffle bag is 15 kg.</div>
          <p class="tips__note-text">
            The weight limit for a duffle bag is set at 15 kg. Your fully loaded duffle bag mustn't exceed this limit.
            According to the rules set by the park authority, a porter can carry up to 20 kg, but this weight also
            includes their own climbing gear, such as a sleeping bag, pad, and warm clothes. If your bag is heavier,
            redistribute some items to the duffle bag of a travel companion who has packed lighter, or carry the extra
            weight in your own backpack.
          </p>
          <p class="tips__note-text">
            Alternatively, if you prefer to keep items that exceed the weight limit, you can hire an additional porter
            for the entire climb at an extra fee.
          </p>
        </div>
      </section>
      <section class="tips__section" id="section-4">
        <div class="tips__headline">
          <div class="tips__headline-picture">
            <img src="@/assets/img/tips/tips-clock.svg" alt="Clock">
          </div>
          <h2 class="tips__title">When does the trip start?</h2>
        </div>
        <p class="tips__text">
          Our consultant will inform you of the trip's starting time at the orientation briefing.
        </p>
        <p class="tips__text">
          Please come to the hotel reception desk on time. Sometimes, we have many trekking groups departing on the
          same day. To avoid congestion in the departure zone, we rely on your cooperation. Coming earlier or later
          may delay the departure of your fellow travelers from your and other groups.
        </p>
        <p class="tips__text">
          It will be the right time to give us suitcases and other bags you won't need on the hike. Our consultants will
          help you with tagging and registering your luggage.
        </p>
      </section>
      <section class="tips__section" id="section-5">
        <div class="tips__headline">
          <div class="tips__headline-picture">
            <img src="@/assets/img/tips/tips-luggage.svg" alt="Luggage">
          </div>
          <h2 class="tips__title">Luggage & valuables storage at Altezza Travel</h2>
        </div>
        <p class="tips__text">We have two storage facilities at the Aishi Machame climbing base:</p>
        <p class="tips__text">
          <b>A locked storage room for suitcases, bags, and other bulky items.</b> This room is under 24/7 video surveillance
          and security patrol. While you’re off on the hike, only authorized Altezza staff may enter this room.
        </p>
        <p class="tips__text">
          If you have any valuable items, don’t leave them in your suitcase. We have a better option for that.
        </p>
        <p class="tips__text">
          <b>In our safe room for valuables,</b> you may leave cash, laptops, jewelry, and other expensive small-sized
          items. This safe room is fire-proof, and equipped with 24/7 video surveillance and a motion sensor alarm system.
          Our saferoom is insured for $600,000, and to date, we have never had to file an insurance claim. This place is
          by far one of the safest locations in Kilimanjaro.
        </p>
        <p class="tips__text">
          When accepting your valuables into our care, we will ask you to itemize them in a special form and sign it.
          If you choose to leave cash with us, our consultant will use an automatic counter to ensure accuracy. After
          counting, our manager will provide a receipt listing the serial numbers of the bills you entrusted us. We will
          seal your cash in an airtight plastic bag for added protection. This approach ensures we know exactly what you
          have deposited into our care.
        </p>
        <p class="tips__text">
          We would appreciate it if you put all valuable items into a small purse or bag to fit in the cell of our safe
          box. If you don’t have a suitable bag, our Tanzania team will provide appropriate packaging.
        </p>
        <p class="tips__text">The use of storage services is free of charge.</p>
        <p class="tips__text">After the climb, our managers will personally meet you and return the luggage and valuables</p>
        <div class="tips__note">
          <div class="tips__note-title">Cash and passports are not necessary on Kilimanjaro</div>
          <p class="tips__note-text">
            Taking a small amount of cash will be a good idea if you plan to buy chocolate or cookies on the way to the
            National Park or souvenirs on the way back. Otherwise, you won't need any cash while on the hike. The
            Kilimanjaro National Park Authority doesn't allow travelers to take more than $200 to the mountain.
          </p>
          <p class="tips__note-text">
            It is also essential to remember that our fellow operators report that thefts occur to Kilimanjaro trekkers
            from time to time. While we haven't had a single case in our expeditions, we strongly recommend leaving all
            valuables, including passports, in secure storage at our office for your peace of mind. We handle all entry
            permits electronically, so there's no need to carry such documents with you.
          </p>
          <p class="tips__note-text">
            Additionally, there is a risk of heavy rainfall or high humidity, which can lead to these critical documents
            getting soaked or otherwise damaged.
          </p>
        </div>
      </section>
      <section class="tips__section" id="section-6">
        <div class="tips__headline">
          <div class="tips__headline-picture">
            <img src="@/assets/img/tips/tips-support.svg" alt="Support">
          </div>
          <h2 class="tips__title">Meeting your Kilimanjaro team</h2>
        </div>
        <p class="tips__text">
          You will meet your Kilimanjaro guides on the expedition’s start day. A member of our operations team will
          introduce you to the lead guide and his assistants, who will help pack your gear into the team car. An
          unlimited amount of bottled water will be available at the departure area for refilling your flasks and
          CamelBaks, ensuring you are well-hydrated for the journey ahead.
        </p>
        <p class="tips__text">
          All our lead guides earned the Wilderness First Responder badge. It is the highest guiding certification
          available in Tanzania. Such training means that a guide knows what to do if a medical emergency happens in
          remote areas. Over the years of training, our guides learned how to administer proper first aid and organize
          professional evacuations.
        </p>
        <p class="tips__text">
          Altezza guides also have strong knowledge of Kilimanjaro wildlife, plants, and terrain, which they will share
          with you on the trek.
        </p>
        <p class="tips__text" style="text-align: center"><b>You may learn more about our guides in this video:</b></p>
        <div class="tips__video">
          <iframe
            srcdoc="<style>
            * {
              padding: 0;
              margin: 0;
              overflow: hidden;
            }

            body, html {
              height: 100%;
            }

            a {
              display: flex;
              height: 100%;
            }

            img {
              position: absolute;
              width: 100%;
              height: 100%;
              inset: 0;
            }

            svg {
              margin: auto;
              filter: drop-shadow(1px 1px 10px hsl(206.5, 70.7%, 8%));
              transition: all 0.25s ease-in-out;
            }

            body:hover svg {
              filter: drop-shadow(1px 1px 10px hsl(206.5, 0%, 10%));
              transform: scale(1.2);
            }
          </style>
          <a href='https://www.youtube.com/embed/aaQfHcIVjW8?si=fXm72KsJFdZ6aQ3J&autoplay=1&mute=1'>
            <img src='https://img.youtube.com/vi/aaQfHcIVjW8/maxresdefault.jpg' alt='Preview'>
            <svg xmlns='http://www.w3.org/2000/svg' width='80' height='59' fill='none'><path fill='#fff' d='M21.332 8h36v42.667h-36z'/><path fill='#E60000' d='M78.13 9.985c-1.6-4.923-6.895-7.905-11.767-8.589a249.56 249.56 0 0 0-52.725 0C8.765 2.08 3.456 5.033 1.87 9.985a99.938 99.938 0 0 0 0 38.493c1.6 4.916 6.894 7.905 11.767 8.589a249.568 249.568 0 0 0 52.724 0c4.873-.684 10.182-3.637 11.767-8.59a99.937 99.937 0 0 0 0-38.492Zm-47.7 34.573v-30.66l24.958 15.33c-8.414 5.17-16.58 10.181-24.958 15.33Z'/></svg>
          </a>
        "
            class="tips__iframe"
            loading="lazy"
            src="https://www.youtube.com/embed/aaQfHcIVjW8?si=fXm72KsJFdZ6aQ3J"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
      </section>
      <section class="tips__section" id="section-7">
        <div class="tips__headline">
          <div class="tips__headline-picture">
            <img src="@/assets/img/tips/tips-gps.svg" alt="GPS">
          </div>
          <h2 class="tips__title">GPS link to share with your family</h2>
        </div>
        <p class="tips__text">
          On the morning of departure, our managers will provide you with an online link connected to the GPS tracker
          your guide will carry during the climb. You can share this link with family, friends, or anyone who wants to
          follow your progress up the mountain. They'll be able to see the real-time location of your trekking group.
        </p>
        <div class="tips__note">
          <div class="tips__note-title">The GPS isn’t available in the rainforest</div>
          <p class="tips__note-text">
            GPS service is often unavailable in the rainforest as the dense tree canopies block the signal. But there’s
            no need to worry—the connection will resume once the tracker can receive data again. If your loved ones have
            any concerns during your trek, they can always contact us using the contact information provided at the end
            of this letter.
          </p>
        </div>
      </section>
      <section class="tips__section" id="section-8">
        <div class="tips__headline">
          <div class="tips__headline-picture">
            <img src="@/assets/img/tips/tips-water.svg" alt="Water">
          </div>
          <h2 class="tips__title">Staying hydrated on Kilimanjaro</h2>
        </div>
        <p class="tips__text"><b>Why drinking water is so important on Kilimanjaro?</b></p>
        <div class="tips__video">
          <iframe
            srcdoc="<style>
            * {
              padding: 0;
              margin: 0;
              overflow: hidden;
            }

            body, html {
              height: 100%;
            }

            a {
              display: flex;
              height: 100%;
            }

            img {
              position: absolute;
              width: 100%;
              height: 100%;
              inset: 0;
            }

            svg {
              margin: auto;
              filter: drop-shadow(1px 1px 10px hsl(206.5, 70.7%, 8%));
              transition: all 0.25s ease-in-out;
            }

            body:hover svg {
              filter: drop-shadow(1px 1px 10px hsl(206.5, 0%, 10%));
              transform: scale(1.2);
            }
          </style>
          <a href='https://www.youtube.com/embed/Se6y4n_FftU?si=NEo2tBmG3lcleDC-&autoplay=1&mute=1'>
            <img src='https://img.youtube.com/vi/Se6y4n_FftU/maxresdefault.jpg' alt='Preview'>
            <svg xmlns='http://www.w3.org/2000/svg' width='80' height='59' fill='none'><path fill='#fff' d='M21.332 8h36v42.667h-36z'/><path fill='#E60000' d='M78.13 9.985c-1.6-4.923-6.895-7.905-11.767-8.589a249.56 249.56 0 0 0-52.725 0C8.765 2.08 3.456 5.033 1.87 9.985a99.938 99.938 0 0 0 0 38.493c1.6 4.916 6.894 7.905 11.767 8.589a249.568 249.568 0 0 0 52.724 0c4.873-.684 10.182-3.637 11.767-8.59a99.937 99.937 0 0 0 0-38.492Zm-47.7 34.573v-30.66l24.958 15.33c-8.414 5.17-16.58 10.181-24.958 15.33Z'/></svg>
          </a>
        "
            class="tips__iframe"
            loading="lazy"
            src="https://www.youtube.com/embed/Se6y4n_FftU?si=NEo2tBmG3lcleDC-"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        <p class="tips__text">
          Staying well-hydrated is crucial for smooth acclimatization. Each day, your body will require around 3-4 liters
          of water. In simple terms, you’ll need more water than in your daily life because you’ll be expending
          significant energy to trek up the mountain. As you ascend, your body works hard to regulate temperature,
          metabolism, and various physiological functions, requiring increased fluid intake. Additionally,
          the acclimatization process itself demands extra hydration to counteract the lower oxygen levels in the atmosphere.
        </p>
        <p class="tips__text">
          We suggest drinking in small sips regularly. Our guides will ensure there are stops about every 30 minutes for
          rest and hydration.
        </p>
        <p class="tips__text">
          It’s also highly recommended to use a CamelBak, as it allows you to conveniently drink while on the move. If
          you have one, make sure it can hold at least 2 liters of water.
        </p>
        <h3 class="tips__title">Where to get water?</h3>
        <p class="tips__text">
          On the departure date, bottled water will be ready and waiting in the hotel’s reception area. Our guides will
          assist you in filling up your flasks and CamelBaks. Additionally, water will be available in the team bus while
          driving to the trailhead.
        </p>
        <p class="tips__text">
          During the hike, our team will source water from mountain streams, boiling it and purifying it with Water Guard
          pills to ensure it's safe to drink. However, the purification tablets can leave a mild chemical taste in the
          water. If you're sensitive to that taste or prefer your water without these additives, you can request the
          guides to stick to boiling only. When we climb Kilimanjaro ourselves, we prefer simple boiling since it is
          enough to ensure water safety without chemicals.
        </p>
        <p class="tips__text">
          And for those who wish to enhance the taste, bringing along electrolyte powder or tablets is a good idea.
          They're especially useful in the mountains because they not only hydrate you but also replace important
          electrolytes like sodium, potassium, and magnesium lost during exertion at high altitudes.
        </p>
        <p class="tips__text">
          With over 800 expeditions annually for more than 4500 climbers, we've had no incidents related to water quality.
        </p>
        <h3 class="tips__title">CamelBaks and flasks should be 2+ liters</h3>
        <p class="tips__text">
          Ensure that your flasks and CamelBaks can hold at least 2 liters of water. On the hike, water will be accessible
          only at the camps, and having a 2-liter supply is essential for the journey between camps.
        </p>
        <p class="tips__text">
          If you happen to run out of water, please inform our guides immediately; they always carry extra supplies to
          handle situations like this.
        </p>
        <div class="tips__note">
          <div class="tips__note-title">Bottled water for the entire hike</div>
          <p class="tips__note-text">
            If you choose to have bottled water for the entire trek, we offer this service at an additional charge.
            Apart from the costs of water, this extra payment will cover the wages, meals, and other related expenses.
          </p>
        </div>
        <p class="tips__text">
          We also recommend bringing 1-liter thermoses. Our guides will carry these for the group, but having more tea
          is always better. It's a good idea to have your own thermos to ensure you have enough warm beverages to keep
          you fully energized during the summit hike.
        </p>
      </section>
      <section class="tips__section" id="section-9">
        <div class="tips__headline">
          <div class="tips__headline-picture">
            <img src="@/assets/img/tips/tips-medical.svg" alt="Medical">
          </div>
          <h2 class="tips__title">Medical check-ups</h2>
        </div>
        <p class="tips__text">
          Our guides will conduct two mandatory medical checks daily—one in the morning after breakfast and another in
          the evening after dinner. It is done to ensure that your acclimatization progresses smoothly. It’s mandatory
          to attend these checks even if you’re feeling well and have no health complaints.
        </p>
        <p class="tips__text">
          The check-ups are easy and short: guides will measure your oxygen levels using oximeters and inquire about
          symptoms like headaches, vomiting, nausea, diarrhea, or difficulty with breathing. It’s vital to be as
          transparent with your guide as you would be with your doctor, openly discussing any discomfort or symptoms
          you may be experiencing. Your honesty will enable the guides to respond appropriately, whether that means
          providing supplemental oxygen, administering additional Diamox or other medications, or adjusting your trekking
          pace to accommodate your physical condition. The check-ups are conducted individually to ensure a confidential
          environment where you can openly discuss any concerns or symptoms.
        </p>
        <p class="tips__text">
          If you are taking any prescription medications, telling us about them in the medical form on your Personal
          Trip Board is essential. We will pass this information on to our guides to ensure your safety and well-being
          during the expedition and take relevant precautions.
        </p>
        <p class="tips__text"><b>To learn more about the use of bottled oxygen systems at Altezza, check out this video:</b></p>
        <div class="tips__video">
          <iframe
            srcdoc="<style>
            * {
              padding: 0;
              margin: 0;
              overflow: hidden;
            }

            body, html {
              height: 100%;
            }

            a {
              display: flex;
              height: 100%;
            }

            img {
              position: absolute;
              width: 100%;
              height: 100%;
              inset: 0;
            }

            svg {
              margin: auto;
              filter: drop-shadow(1px 1px 10px hsl(206.5, 70.7%, 8%));
              transition: all 0.25s ease-in-out;
            }

            body:hover svg {
              filter: drop-shadow(1px 1px 10px hsl(206.5, 0%, 10%));
              transform: scale(1.2);
            }
          </style>
          <a href='https://www.youtube.com/embed/ygegdam7-Kk?si=ij7IOPoiQPXza03M&autoplay=1&mute=1'>
            <img src='https://img.youtube.com/vi/ygegdam7-Kk/maxresdefault.jpg' alt='Preview'>
            <svg xmlns='http://www.w3.org/2000/svg' width='80' height='59' fill='none'><path fill='#fff' d='M21.332 8h36v42.667h-36z'/><path fill='#E60000' d='M78.13 9.985c-1.6-4.923-6.895-7.905-11.767-8.589a249.56 249.56 0 0 0-52.725 0C8.765 2.08 3.456 5.033 1.87 9.985a99.938 99.938 0 0 0 0 38.493c1.6 4.916 6.894 7.905 11.767 8.589a249.568 249.568 0 0 0 52.724 0c4.873-.684 10.182-3.637 11.767-8.59a99.937 99.937 0 0 0 0-38.492Zm-47.7 34.573v-30.66l24.958 15.33c-8.414 5.17-16.58 10.181-24.958 15.33Z'/></svg>
          </a>
        "
            class="tips__iframe"
            loading="lazy"
            src="https://www.youtube.com/embed/ygegdam7-Kk?si=ij7IOPoiQPXza03M"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        <h3 class="tips__title">The contents of our medical kits</h3>
        <p class="tips__text">
          We equip our teams with two different medical kits. The first one is known as the “camping kit,” which includes
          medication for every medical issue that could potentially arise on Kilimanjaro. We use this kit within the camp
          or if a problem is identified during a medical check-up.
        </p>
        <p class="tips__text">
          While trekking from one camp to another, our guides carry a smaller tactical medical kit. It is used to address
          emergencies and physical injuries that may occur during the trek.
        </p>
        <p class="tips__text">
          You can <a href="https://altezza.travel/en/articles/first-aid-kits-altezza-travel" target="_blank">read this article</a>
          for a better understanding of the contents of our medical kits and how we use them.
        </p>
        <h3 class="tips__title">Diamox and what we use it for</h3>
        <p class="tips__text"><b>You may get key information about Diamox from this video:</b></p>
        <div class="tips__video">
          <iframe
            srcdoc="<style>
            * {
              padding: 0;
              margin: 0;
              overflow: hidden;
            }

            body, html {
              height: 100%;
            }

            a {
              display: flex;
              height: 100%;
            }

            img {
              position: absolute;
              width: 100%;
              height: 100%;
              inset: 0;
            }

            svg {
              margin: auto;
              filter: drop-shadow(1px 1px 10px hsl(206.5, 70.7%, 8%));
              transition: all 0.25s ease-in-out;
            }

            body:hover svg {
              filter: drop-shadow(1px 1px 10px hsl(206.5, 0%, 10%));
              transform: scale(1.2);
            }
          </style>
          <a href='https://www.youtube.com/embed/ZcuA_GapbTQ?si=lc1V-cdJrKBesG3i&autoplay=1&mute=1'>
            <img src='https://img.youtube.com/vi/ZcuA_GapbTQ/maxresdefault.jpg' alt='Preview'>
            <svg xmlns='http://www.w3.org/2000/svg' width='80' height='59' fill='none'><path fill='#fff' d='M21.332 8h36v42.667h-36z'/><path fill='#E60000' d='M78.13 9.985c-1.6-4.923-6.895-7.905-11.767-8.589a249.56 249.56 0 0 0-52.725 0C8.765 2.08 3.456 5.033 1.87 9.985a99.938 99.938 0 0 0 0 38.493c1.6 4.916 6.894 7.905 11.767 8.589a249.568 249.568 0 0 0 52.724 0c4.873-.684 10.182-3.637 11.767-8.59a99.937 99.937 0 0 0 0-38.492Zm-47.7 34.573v-30.66l24.958 15.33c-8.414 5.17-16.58 10.181-24.958 15.33Z'/></svg>
          </a>
        "
            class="tips__iframe"
            loading="lazy"
            src="https://www.youtube.com/embed/ZcuA_GapbTQ?si=lc1V-cdJrKBesG3i"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        <p class="tips__text">
          Diamox is a medication we provide our hikers to ease acclimatization. Diamox aids in dealing with oxidative
          stress that occurs at high altitudes, and makes it easier for your body to adjust to the lower oxygen levels
          and avoid symptoms of Acute Mountain Sickness (AMS).
        </p>
        <p class="tips__text">
          Our experience shows Diamox is quite effective and can make the acclimatization process significantly less
          stressful for most hikers. We recommend consulting with your doctor before the trip to make sure you have no
          potential allergic reactions and can take Diamox safely.
        </p>
        <p class="tips__text">
          There's no need to bring Diamox with you as it's a standard component of our medical kits and is readily
          available anytime you need it. If you choose to use Diamox, you can start taking it at the hotel a day before
          the climb begins. Starting early can help your body adjust and maximize the medication's effectiveness. Ask
          our Tanzania consultants team, and they'll provide it for you.
        </p>
        <p class="tips__text">
          The recommended daily dosage for an adult is two 125 mg pills daily—one in the morning and the second around
          midday. Avoid taking Diamox before sleep as it can increase urination and potentially disturb your sleep.
        </p>
        <p class="tips__text">
          While taking Diamox, you might experience a tingling sensation in your fingertips and face. There's no need
          for alarm; this side effect is normal and usually subsides as you continue the medication.
        </p>
        <div class="tips__note">
          <div class="tips__note-title">Consult your doctor</div>
          <p class="tips__note-text">
            We strongly advise consulting with your doctor before deciding whether to take Diamox. This ensures your
            safety and helps you make an informed decision based on your health condition and medical history.
          </p>
          <p class="tips__note-text">
            It’s important to note that certain individuals, including pregnant women and those with diabetes or
            allergies to sulfa drugs, should avoid taking Diamox. Always prioritize your well-being and seek professional
            medical advice to determine if Diamox is a suitable option for you before starting your trip.
          </p>
        </div>
        <h3 class="tips__title">Why you should avoid alcohol on Kilimanjaro</h3>
        <p class="tips__text">
          When trekking Kilimanjaro, it's important to avoid drinking alcohol. Not only is it prohibited by the National
          Park rules, but alcohol consumption can also significantly hinder the acclimatization process. Alcohol can
          counteract the effects of Diamox. Furthermore, in the event of a health issue on the mountain, consuming
          alcohol can render any medication taken afterward ineffective.
        </p>
        <p class="tips__text"><b>To learn more about acclimatization on Kilimanjaro, watch this video:</b></p>
        <div class="tips__video">
          <iframe
            srcdoc="<style>
            * {
              padding: 0;
              margin: 0;
              overflow: hidden;
            }

            body, html {
              height: 100%;
            }

            a {
              display: flex;
              height: 100%;
            }

            img {
              position: absolute;
              width: 100%;
              height: 100%;
              inset: 0;
            }

            svg {
              margin: auto;
              filter: drop-shadow(1px 1px 10px hsl(206.5, 70.7%, 8%));
              transition: all 0.25s ease-in-out;
            }

            body:hover svg {
              filter: drop-shadow(1px 1px 10px hsl(206.5, 0%, 10%));
              transform: scale(1.2);
            }
          </style>
          <a href='https://www.youtube.com/embed/cBQcW7O1z6A?si=o_LoZcBlClyG-6cC&autoplay=1&mute=1'>
            <img src='https://img.youtube.com/vi/cBQcW7O1z6A/maxresdefault.jpg' alt='Preview'>
            <svg xmlns='http://www.w3.org/2000/svg' width='80' height='59' fill='none'><path fill='#fff' d='M21.332 8h36v42.667h-36z'/><path fill='#E60000' d='M78.13 9.985c-1.6-4.923-6.895-7.905-11.767-8.589a249.56 249.56 0 0 0-52.725 0C8.765 2.08 3.456 5.033 1.87 9.985a99.938 99.938 0 0 0 0 38.493c1.6 4.916 6.894 7.905 11.767 8.589a249.568 249.568 0 0 0 52.724 0c4.873-.684 10.182-3.637 11.767-8.59a99.937 99.937 0 0 0 0-38.492Zm-47.7 34.573v-30.66l24.958 15.33c-8.414 5.17-16.58 10.181-24.958 15.33Z'/></svg>
          </a>
        "
            class="tips__iframe"
            loading="lazy"
            src="https://www.youtube.com/embed/cBQcW7O1z6A?si=o_LoZcBlClyG-6cC"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
      </section>
      <section class="tips__section" id="section-10">
        <div class="tips__headline">
          <div class="tips__headline-picture">
            <img src="@/assets/img/tips/tips-devices.svg" alt="Devices">
          </div>
          <h2 class="tips__title">Use of electronic devices on Kilimanjaro</h2>
        </div>
        <p class="tips__text">There are two things you should keep in mind while using gadgets on Kilimanjaro:</p>
        <p class="tips__text">
          <b>There are no power sockets available on the mountain.</b> Bring spare batteries, power banks, or solar
          chargers to keep your devices running. Solar batteries charge most efficiently when attached to the outside
          of your backpack so that they can get charged while you’re hiking.
        </p>
        <p class="tips__text">
          Conserve your device’s battery life by switching it to airplane mode or switching it off altogether,
          especially when the cellular signal is weak, typically at lower camps, or when you don’t intend to send
          messages or make calls.
        </p>
        <p class="tips__text">
          <b>Cold temperatures can deplete battery life rapidly.</b> This is particularly important during the night
          when temperatures drop significantly. To prevent this, keep your devices in warm places, like inside your
          sleeping bag, an insulated bag or pouch, or nestled within your summit or fleece jacket during summit night.
        </p>
      </section>
      <section class="tips__section" id="section-11">
        <div class="tips__headline">
          <div class="tips__headline-picture">
            <img src="@/assets/img/tips/tips-shower.svg" alt="Shower">
          </div>
          <h2 class="tips__title">Toilets and showers on Kilimanjaro</h2>
        </div>
        <p class="tips__text">
          In short, there are two ways to use the restroom on Kilimanjaro:
        </p>
        <p class="tips__text">
          There are stationary public restrooms in each camp, and, occasionally, along the way between the camps. While
          the Kilimanjaro National Park is working hard to maintain proper hygiene in these toilets, hundreds of
          trekkers use them every day, and the condition is often not what we are all used to. So, if you are a seasoned
          hiker, and have no problems with that, this may be a good option. Toilet paper is provided by our guides,
          feel free to ask them when needed.
        </p>
        <h3 class="tips__title">Portable toilets</h3>
        <p class="tips__text">
          If you prefer more comfort, then the best option on Kilimanjaro is hiring a portable private toilet. It goes
          with a tent for more privacy, and our crew usually sets it up within a short walk from your sleeping tent.
          There will be a special porter taking care of this portable restroom: carrying it, cleaning it every day,
          and refilling it with chemicals.
        </p>
        <p class="tips__text">
          We charge extra for the option of portable toilets to cover the extra costs associated with hiring an
          additional porter and purchasing equipment. Each toilet may be used by up to four hikers. If you're hiking
          alone and want to save money on toilets, ask your climb coordinators about sharing with 1-3 other hikers in
          your group.
        </p>
        <p class="tips__text">
          If you have an urgent need on the trek from camp to camp, you may go to the nearest bushes. We recommend
          taking hygenic bags to avoid leaving wet wipes and other litter on the mountain. Those can be disposed of in
          the camps.
        </p>
        <h3 class="tips__title">Portable showers</h3>
        <p class="tips__text">
          For those who prefer not to skip showers during the 7-day trek, portable showers are available for hire on
          Kilimanjaro, as there are no stationary showers on the mountain. Each portable shower has a designated porter
          who ensures it’s clean, filled with hot water, and conveniently located around the camp. Keep in mind that
          this facility is quite basic, without strong water pressure like at home.
        </p>
        <p class="tips__text">
          If you wish to arrange a portable shower during your trek, you can request it through your personal trip board.
        </p>
        <h3 class="tips__title">Morning and evening hygiene on Kilimanjaro</h3>
        <p class="tips__text">
          Our crew prepares hot water for washing the face and brushing teeth to offer more comfort in the wild. You may
          use wet wipes to clean the body (which is what most hikers do), or consider hiring a portable shower.
        </p>
      </section>
      <section class="tips__section" id="section-12">
        <div class="tips__headline">
          <div class="tips__headline-picture">
            <img src="@/assets/img/tips/tips-leaf.svg" alt="Leaf">
          </div>
          <h2 class="tips__title">Environmentally responsible travel</h2>
        </div>
        <p class="tips__text">
          About 50,000 hikers trod the trails of Kilimanjaro every year, and this number is rising. Over the past ten
          years, more people visited Kilimanjaro than over a century preceding that time. To preserve the beauty of
          Kilimanjaro for future generations, we need to be responsible about our expeditions. This means:
        </p>
        <p class="tips__text">
          <b>Don't leave any waste on the mountain.</b> This includes toilet paper and napkins. Use ziploc bags to store
          those while hiking from camp to camp, and then leave them in a litter bin at a campsite. This applies to
          depleted batteries. As there are no recycling facilities, we highly recommend taking them out of Tanzania and
          disposing of them at your home country, where collection centers are most likely available.
        </p>
        <p class="tips__text">
          Every year in November and April we organize special Kilimanjaro cleaning expeditions and collect dozens bags
          of waste. You may read more about this
          <a href="https://altezza.travel/articles/no-litter-on-kilimanjaro" target="_blank">in this article on our website</a>.
        </p>
        <p class="tips__text">
          Smokers should be especially attentive: a casually thrown cigarette butt may ignite the bush around. Please,
          put those in a ziploc and dispose of them at a camp.
        </p>
        <p class="tips__text">
          Bushfires on Kilimanjaro escalate quickly and become quite severe. In 2020 and 2022, Altezza Travel responded
          to these emergencies by sending the largest crew among all tour operators to assist the National Park
          <a href="https://altezza.travel/en/articles/fire-on-kilimanjaro" target="_blank">in fighting the fires</a>.
          We saw that the fire destroyed many hectares of bush, and it will take years for it to grow back.
        </p>
        <p class="tips__text">
          <b>Don’t pick flowers or collect any natural objects, like stones, pieces of wood, or obsidian.</b> Though
          tempting, when thousands of tourists take parts of the local ecosystem away, it is extremely harmful to
          Kilimanjaro. In addition, it’s illegal to take those out of the country.
        </p>
        <p class="tips__text">
          <b>Don’t take disposable plastic bottles with you to Tanzania.</b> Single-use plastic bottles are prohibited
          on Kilimanjaro. Though there is no rigorous enforcement, opt for more sustainable alternatives. You may hire
          a suitable flask from us.
        </p>
        <p class="tips__text">
          <b>Don’t wash your hands, body, or clothes in the mountain streams.</b> Other hikers and villagers lower down
          the mountain use that water for drinking and cooking.
        </p>
        <p class="tips__text">
          <b>Don’t feed wild animals, if you happen to see any on the way.</b> If you see wild animals like monkeys,
          rodents or birds while hiking Kilimanjaro, please don't feed them. Feeding them makes them rely on people for
          food, and they forget how to live in the wild.
        </p>
      </section>
      <section class="tips__section" id="section-13">
        <div class="tips__headline">
          <div class="tips__headline-picture">
            <img src="@/assets/img/tips/tips-beginning.svg" alt="Info">
          </div>
          <h2 class="tips__title">Beginning your trek</h2>
        </div>
        <p class="tips__text">
          Your Kilimanjaro trip will start with a ride from the hotel to the trailhead. Depending on your climbing
          route, the ride may take 30 minutes to three hours. This trip is hardly boring: on the way, you’ll see
          authentic Tanzanian villages, the life of the local people, and beautiful Kilimanjaro scenery.
        </p>
        <div class="tips__note">
          <div class="tips__note-title">Where to check detailed day-by-day itinerary?</div>
          <p class="tips__note-text">
            See the Trip Itinerary in the Personal Trip Board to keep track of your hiking schedule
          </p>
        </div>
        <p class="tips__text">
          The team will make a short stop at the Kilimanjaro National Park entry gate to get entry permits endorsed and
          register with the rescue service. The guides will take care of these formalities, and you will have a short
          rest in the picnic zone before hiking to your first Kilimanjaro camp.
        </p>
      </section>
      <section class="tips__section" id="section-14">
        <div class="tips__headline">
          <div class="tips__headline-picture">
            <img src="@/assets/img/tips/tips-mountain.svg" alt="Ascent">
          </div>
          <h2 class="tips__title">How will you spend most of your days on Kilimanjaro?</h2>
        </div>
        <p class="tips__text">
          You will start your Kilimanjaro days around 06:30 with a morning coffee served right to your tent. Then, after
          washing your face, brushing your teeth, and getting dressed, you will join your co-hikers in the mess tent for
          breakfast.
        </p>
        <p class="tips__text">
          After breakfast, the trekkers will visit a medical tent individually for a quick check-up. The guides will
          measure oxygen saturation level, ask several questions about general well-being, and fill in a medical card.
          Then, they will offer Diamox pills for those opting to take them. Afterward, you’ll grab your backpacks and
          begin the trek to the next camp. Every evening, our guides will announce the trekking plan for the next day,
          so you know what to expect in advance.
        </p>
        <p class="tips__text">
          Depending on the route you choose for the trip, daily treks usually take 3-7 hours. Typically, the hikers
          don’t get too tired: the guides make a small stop after each 30-40 mins to rest, sip water, and take pictures
          of the beautiful surroundings around you.
        </p>
        <p class="tips__text">
          While some travelers might move more quickly, speed doesn't necessarily determine the likelihood of success.
          Paying attention to your body and maintaining a steady pace is vital. This ensures you conserve energy, and
          don't overstress your body, which is crucial for acclimatization. Often, the group splits up and every hiker
          can maintain a pace that is comfortable to them.
        </p>
        <p class="tips__text">
          <b>To learn more about a proper trekking pace, check out this Youtube video by the Altezza Team:</b>
        </p>
        <div class="tips__video">
          <iframe
            srcdoc="<style>
            * {
              padding: 0;
              margin: 0;
              overflow: hidden;
            }

            body, html {
              height: 100%;
            }

            a {
              display: flex;
              height: 100%;
            }

            img {
              position: absolute;
              width: 100%;
              height: 100%;
              inset: 0;
            }

            svg {
              margin: auto;
              filter: drop-shadow(1px 1px 10px hsl(206.5, 70.7%, 8%));
              transition: all 0.25s ease-in-out;
            }

            body:hover svg {
              filter: drop-shadow(1px 1px 10px hsl(206.5, 0%, 10%));
              transform: scale(1.2);
            }
          </style>
          <a href='https://www.youtube.com/embed/7n6uWEEpam8?si=FwZk1g6f7tpedK4L&autoplay=1&mute=1'>
            <img src='https://img.youtube.com/vi/7n6uWEEpam8/maxresdefault.jpg' alt='Preview'>
            <svg xmlns='http://www.w3.org/2000/svg' width='80' height='59' fill='none'><path fill='#fff' d='M21.332 8h36v42.667h-36z'/><path fill='#E60000' d='M78.13 9.985c-1.6-4.923-6.895-7.905-11.767-8.589a249.56 249.56 0 0 0-52.725 0C8.765 2.08 3.456 5.033 1.87 9.985a99.938 99.938 0 0 0 0 38.493c1.6 4.916 6.894 7.905 11.767 8.589a249.568 249.568 0 0 0 52.724 0c4.873-.684 10.182-3.637 11.767-8.59a99.937 99.937 0 0 0 0-38.492Zm-47.7 34.573v-30.66l24.958 15.33c-8.414 5.17-16.58 10.181-24.958 15.33Z'/></svg>
          </a>
        "
            class="tips__iframe"
            loading="lazy"
            src="https://www.youtube.com/embed/7n6uWEEpam8?si=FwZk1g6f7tpedK4L"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
        <p class="tips__text">
          By midday, you'll arrive at the next camp. Our camp crew and porters will have reached it before you, setting
          up the tents and preparing lunch in anticipation of your arrival. Following a short rest, our guides will take
          you on an acclimatization hike. These are trips to a slightly higher altitude, with elevation gain of about
          150-200 meters, where you spend a bit of time before descending back to camp. Acclimatization hikes take 1-1.5
          hours. Though short, these hikes make your acclimatization transition faster and easier. They aren’t mandatory,
          but we recommend joining them.
        </p>
      </section>
      <section class="tips__section" id="section-15">
        <div class="tips__headline">
          <div class="tips__headline-picture">
            <img src="@/assets/img/tips/tips-food.svg" alt="Food">
          </div>
          <h2 class="tips__title">What is the food like on the hike?</h2>
        </div>
        <p class="tips__text">
          On the hike, you’ll be expending significant energy, and we designed our Kilimanjaro menu with that in mind.
          Professional nutritionists made it varied and tasty, while nutritious enough to give you plenty of energy.
        </p>
        <p class="tips__text">Here are some examples of what we might serve on your hike.</p>
        <h3 class="tips__title">Breakfasts</h3>
        <p class="tips__text">
          For breakfast, we typically offer oat porridge, a choice of scrambled/fried eggs or omelet, beef or chicken
          sausages, fried tomatoes, baked beans and avocado slices. Every breakfast is concluded with fresh fruits and
          a choice of tea or coffee.
        </p>
        <h3 class="tips__title">Lunch and dinner</h3>
        <p class="tips__text">
          Lunch and dinner options include a variety of soups like vegetable, butternut, leek, and beef, as well as
          dishes such as vegetable salads, fresh avocado, fried or mashed potatoes, spaghetti, rice, fried chicken in
          coconut and other sauces, and minced meat in tomato sauce. We will also serve vegetable stew, beef stew,
          grilled beef fillet, chicken stew, and sautéed dishes like zucchini with tomato and eggplant with garlic.
          Each meal concludes with seasonal fruits served by our camp waiters, including passion fruit, mango, papaya,
          oranges, and bananas.
        </p>
        <p class="tips__text">
          Our vegetarian and vegan meal plans also offer a wide range of tasty dishes. We can often cater to other
          dietary preferences, please discuss it with your travel manager. You can also ask them for a more detailed
          list of dishes we serve on our expeditions.
        </p>
        <div class="tips__note">
          <div class="tips__note-title">Our cooks prepare meals in challenging conditions</div>
          <p class="tips__note-text">
            Our mountain chefs prepare meals in a camp kitchen, using a camping gas oven. Sometimes, weather conditions
            can be harsh, with wind and rain, and it can be quite cold during certain months. Nevertheless, we work
            tirelessly to maintain the highest possible level of quality even when the environmental conditions are rough.
          </p>
        </div>
      </section>
      <section class="tips__section" id="section-16">
        <div class="tips__headline">
          <div class="tips__headline-picture">
            <img src="@/assets/img/tips/tips-rest.svg" alt="Rest">
          </div>
          <h2 class="tips__title">Things to remember when you rest in the camp</h2>
        </div>
        <p class="tips__text">
          While staying in a camp on Kilimanjaro, it's important to keep a few things in mind:
        </p>
        <p class="tips__text">
          <b>Don’t close the ventilation openings in your tent.</b> Firstly, that leads to less oxygen coming into your
          tent, which might reduce your sleep quality. Secondly, closed ventilation will cause condensation that will
          drip on you.
        </p>
        <p class="tips__text">
          <b>It is prohibited to use open fire in the Kilimanjaro National Park.</b> A tent can catch fire and burn down
          in less than a minute. Therefore, extreme caution with any kind of flame is essential for the safety of
          everyone on the expedition. If you need to smoke, do so at a considerable distance from the tents.
        </p>
        <p class="tips__text">
          <b>Be cautious when moving around the tents when it gets dark.</b> The support ropes of the tents can be easy
          to trip over. Always use your headlamp to light your path when walking between the mess tent and your sleeping
          tent or visiting a washroom.
        </p>
        <p class="tips__text">
          <b>For restroom needs, please use the public facilities provided in each camp.</b> These are available for all
          climbers and crew members. If you're looking for more privacy and comfort, you have the option to hire a
          private portable toilet from us. To preserve the local environment, please avoid using areas near the camp
          for these needs.
        </p>
        <p class="tips__text">
          <b>Be cautious around slippery surfaces.</b> Often, climbers change into different shoes while in camp. It's
          a good idea to bring footwear that has good traction to prevent slips and falls.
        </p>
      </section>
      <section class="tips__section" id="section-17">
        <div class="tips__headline">
          <div class="tips__headline-picture">
            <img src="@/assets/img/tips/tips-socks.svg" alt="Socks">
          </div>
          <h2 class="tips__title">How to keep warm on Kilimanjaro</h2>
        </div>
        <p class="tips__text">
          Weather on Kilimanjaro can be variable. Here are some tips that will help you to stay warm on the trek:
        </p>
        <p class="tips__text">
          <b>Wear layers during the day.</b> Layering is key for day hikes on Kilimanjaro. For the upper body, a
          combination of a synthetic T-shirt or long-sleeve, a fleece jacket, and a membrane jacket works best. For your
          legs, trekking pants with thermal underwear are ideal for most days. At higher elevations, put on your warmest
          gear. For more details about what to wear on Kilimanjaro, please download our
          <a href="https://altezza.travel/en/kilimanjaro-packing-list.pdf" target="_blank">Kilimanjaro Packing List</a>
          if you haven’t already.
        </p>
        <p class="tips__text">
          <b>Change your clothes when you arrive at a camp.</b> As soon as you arrive at the camp, it's essential to
          quickly change out of your sweaty hiking clothes to prevent getting chilled. Ensure you have packed enough
          spare long-sleeve shirts and socks to change into. When choosing hiking clothing, opt for moisture wicking
          synthetic fabrics, as they're designed to dry quickly and keep moisture away from your skin. Alternatively,
          natural fabrics like cotton would also be suitable to wear at camps.
        </p>
        <p class="tips__text">
          Whenever the sun shines on Kilimanjaro, use the opportunity to dry your thermal underwear and other gear.
          The mountain's weather can be unpredictable, so you never know when you'll get another sunny spell.
        </p>
        <p class="tips__text">
          <b>Bring your thermos bottle for the hike.</b> Our guides will be always carrying thermos with tea and cakes
          on the trails. However, we recommend that you take yours as well. The more tea during the day the better.
          Tea with sugar is energizing and can boost your stamina when you get tired.
        </p>
        <p class="tips__text">
          Secondly, depending on the elevation, the temperature of camps at night may drop from -5°C/23°F in the lower
          camps to -10 / 5°F in the higher camps. The water in a hiking flask can get extremely cold or even get frozen.
          Mixing it with the hot water from the thermos will make it safer to drink to avoid a sore throat.
        </p>
        <p class="tips__text">
          <b>Take hand and toe warmers for the summit night.</b> For the summit night on Kilimanjaro, it's a good idea
          to bring hand and toe warmers. You'll likely need 2-3 pairs. Keep in mind that these warmers are typically not
          available for purchase in Tanzania, so ensure you have them on hand before heading out for your trek.
        </p>
        <h3 class="tips__title">How to use chemical warmers on the summit night properly?</h3>
        <p class="tips__text">
          When you need to use a hand warmer, make sure to check the instructions on its package first. Typically,
          you'll need to shake the hand warmer for about 3-4 minutes once you open it. After that, it's ready to go.
          Place the hand warmer between your inner fleece glove and your outer mitten or summit glove for the best warmth.
        </p>
        <p class="tips__text">
          Toe warmers are typically used on the summit night hike. It's best to test the chemical warmers beforehand at
          home or in the hotel to make sure you are comfortable with them.
        </p>
        <h3 class="tips__title">How to stay warm at night</h3>
        <p class="tips__text">
          <b>Make sure you have a suitable sleeping bag.</b> Sleeping bags with comfort level  -12°C/10°F are the best
          for Kilimanjaro. Lighter alternatives may be too cold to sleep in. Also, consider bringing a sleeping bag
          liner, which provides more comfort and enhances the warmth.
        </p>
        <p class="tips__text">
          <b>Sleep in a good thermal set.</b> Bringing three sets of thermal underwear is advisable for your Kilimanjaro
          adventure. 2 sets will be used for daily hiking, and the third one for sleeping and the summit hike. For extra
          comfort, you may bring more sets.
        </p>
        <p class="tips__text">
          <b>We will give you a hot-water bottle for the night.</b> When the team goes to the tents for sleep, our
          porters will give everyone a hot water bottle. Put it into the sleeping bag, and it will keep you warm during
          the night.
        </p>
        <p class="tips__text">
          If you drink water at night - pour it into the thermos. If you keep it in the flask, it will get very cold
          during the night, and drinking it may cause a sore throat.
        </p>
        <h3 class="tips__title">How to keep yourself and your gear dry?</h3>
        <p class="tips__text">
          The weather on Kilimanjaro can be quite unpredictable, so it's wise not to discount the chance of rain. To
          ensure your adventure isn’t dampened by wet gear, there are several measures you should take:
        </p>
        <p class="tips__text">
          <b>Carry waterproof clothing in the daypack:</b> Always have a waterproof poncho and a pair of membrane
          pants/jacket in your daypack. If the weather shifts from sunny to rainy, you can quickly put these on and
          continue your hike comfortably.
        </p>
        <p class="tips__text">
          <b>Pack using plastic or dry bags:</b> Before placing your gear in the duffle bag, pack them in plastic bags
          or dry bags. This adds an extra layer of moisture protection, particularly during heavy rains.
        </p>
        <p class="tips__text">
          <b>Opt for quality waterproof duffle bags:</b> The primary solution against rain is a good waterproof duffle
          bag. These are preferable to large backpacks. At Altezza, we offer custom-designed, appropriately sized duffle
          bags for Kilimanjaro, available for hire at a reasonable price.
        </p>
        <p class="tips__text">
          <b>Bring spare shoes for the rainy season:</b> If you’re hiking in the rainy season, consider carrying an
          extra pair of shoes. Shoes can easily get soaked by stepping into mountain streams or large puddles. Since
          drying opportunities are scarce on Kilimanjaro, especially during the rainy season, a spare pair can be a
          lifesaver.
        </p>
        <p class="tips__text">
          <b>Pack extra socks and gloves:</b> Similarly, pack multiple pairs of socks and fleece gloves. These smaller
          items are difficult to dry, so having spares is advisable for the duration of your trip.
        </p>
      </section>
      <section class="tips__section" id="section-18">
        <div class="tips__headline">
          <div class="tips__headline-picture">
            <img src="@/assets/img/tips/tips-night.svg" alt="Socks">
          </div>
          <h2 class="tips__title">The summit night</h2>
        </div>
        <p class="tips__text">
          Once you get to Kilimanjaro’s summit camp around lunchtime, it’s a good idea to catch some sleep right after
          eating. Getting a good rest is important because there will be a lot of hiking to do continuing through the
          night and into the next morning.
        </p>
        <p class="tips__text">
          We’ll wake you up around 23:00 for a hearty meal and a final check-up before the group starts the summit push.
          Many groups kick off around midnight. However, if you’ve demonstrated a steady and fast hiking pace on the
          previous days and have general hiking experience, our guides will tweak the schedule to suit you. This means
          you can get some extra sleep and start a bit later, around 02:00.
        </p>
        <div class="tips__note">
          <div class="tips__note-title">Why is the Kilimanjaro summit at night?</div>
          <p class="tips__note-text">
            The reason for attempting the Kilimanjaro summit at night is to align your arrival to the highest point of
            Kilimanjaro with the sunrise. The view from the summit is truly breathtaking.
          </p>
          <p class="tips__note-text">
            It is not the only reason, though. Reaching the summit by sunrise allows you to descend to the lower camp
            (Millennium or Horombo) by midday. If you start your summit attempt in the morning, you won’t reach that
            camp before sunset. Descending in the dark is risky due to reduced reaction times and coordination from
            prolonged exposure to high altitudes and fatigue, making hiking during dark hours unsafe.
          </p>
          <p class="tips__note-text">
            Additionally, helicopters are not permitted to operate after sunset due to strict safety protocols and
            therefore helicopter evacuations will not be possible.
          </p>
        </div>
        <p class="tips__text">
          Most groups reach the summit area between 06:30 and 09:00 AM. There, the groups typically spend around 20-30
          minutes taking pictures and celebrating the achievement.
        </p>
        <p class="tips__text">
          Should you feel ready and weather permitting, you can go on a 20-minute trip to explore the nearby glacier.
          For those seeking a more daring adventure, a descent to the crater offers an up-close encounter with the
          Furtwangler glacier and a trek to the Reusch Crater, typically lasting 2-3 hours. However, the decision always
          rests with the guide, prioritizing your safety and taking weather conditions into account.
        </p>
        <h3 class="tips__title">Steady and slow pace on the way to the summit</h3>
        <p class="tips__text">
          When you’re in the summit zone, the oxygen saturation level is twice as low as at sea level. Our very
          experienced guides will be there to help you find the right pace, making sure you’re moving safely and
          comfortably up the mountain. Don’t rush and follow the set pace. Communicate with the guides if you are struggling.
        </p>
        <p class="tips__text">
          The guides will occasionally make stops, allowing you 5 to 10 minutes to rest. If you need more stops,
          please tell that to your guide.
        </p>
        <h3 class="tips__title">Guide’s authority is paramount</h3>
        <p class="tips__text">
          As you prepare for your journey to the summit of Kilimanjaro, it’s crucial to understand that our primary
          goal as a company is to ensure your safe ascent. Everyone at Altezza shares this common objective. However,
          it’s important to recognize that sometimes, due to medical reasons – often related to altitude
          sickness – hikers might not fully understand the risks involved at the final stage of the climb.
        </p>
        <p class="tips__text">
          During the summit hike, we empower our guides to make the critical decision to halt a summit attempt
          when necessary, even if a hiker disagrees. Such interventions are rare, occurring in only 5-10 cases out of
          over 1000. Guides may decide to stop the summit attempt under the following circumstances:
        </p>
        <ul class="tips__list">
          <li>
            If a hiker is moving at an extremely slow pace and likely won’t reach the summit before 11:00. In that case,
            returning to camp before sunset becomes impossible, which is a significant danger.
          </li>
          <li>
            If a hiker experiences serious acclimatization problems, and oxygen supplementation is ineffective,
            making any further ascent unsafe.
          </li>
          <li>Any other medical reasons, making further ascent dangerous at the guide’s sole discretion.</li>
        </ul>
        <p class="tips__text">
          In the rare event that such a decision is made to ensure your safety, we ask for your understanding and
          respect for the guide’s judgment. Sometimes, the extreme altitude might make it difficult for a climber to
          accurately assess the situation and the risks. So, it is vital to trust the professionalism of your guide,
          who has led hundreds or thousands of hikers to the top of Kilimanjaro.
        </p>
        <p class="tips__text">
          This is extremely unlikely to occur. Across all our routes, we have an average success rate of about 95%.
          We carry oxygen, perform medical check-ups, and do everything we can to ensure our climbers summit successfully.
        </p>
        <p class="tips__text">
          In the rare cases when turning back is advised, our clients choose to cooperate, and we never had to evacuate
          somebody against their will. However, we reserve the right to halt an expedition, as life and health are more
          important than the several thousand dollars a climber has invested in the trip.
        </p>
        <p class="tips__text">
          Every year people die trying to ascent Kilimanjaro. However, we at Altezza haven’t had a single lethal
          incident thanks to our strict safety protocols, despite being the largest Kilimanjaro operator.
        </p>
      </section>
      <section class="tips__section" id="section-19">
        <div class="tips__headline">
          <div class="tips__headline-picture">
            <img src="@/assets/img/tips/tips-descent.svg" alt="Descent">
          </div>
          <h2 class="tips__title">Descent from Kilimanjaro</h2>
        </div>
        <p class="tips__text">
          After snapping pictures at the summit and soaking in that awesome feeling of achievement, you’ll start your
          careful descent. It’s crucial to stay attentive and focused; the combination of fatigue and prolonged exposure
          to high altitude can impact coordination, making stumbling more likely. Take it slow, and lean on your
          trekking poles—they won’t just help maintain a steady pace, but will also ease the pressure on your knees and back.
        </p>
        <p class="tips__text">
          As you start your hike back, it's important to extend your trekking poles by about 15 cm (6 inches) to
          accommodate the downhill slope. Don’t run or slide down the slopes. Such actions lead to soil erosion,
          negatively impacting the Kilimanjaro environment.
        </p>
        <p class="tips__text">
          You’ll be back to the summit camp where you began the ascent in about 2-3 hours, which is typically twice as
          fast as the climb up. Once there, you’ll have a brief 1-2 hour rest, and enjoy some tea and lunch. Then,
          you’ll embark on a gradual trek to your final camp on Kilimanjaro, arriving in the evening hours for dinner.
        </p>
        <p class="tips__text">
          The lower camp will offer a picturesque environment to enjoy your last evening on the mountain. You can wander
          around, enjoy the surroundings, and celebrate your climb with fellow hikers over dinner, and then head to bed.
        </p>
        <p class="tips__text">
          Come morning, you’ll wake up, have breakfast, and head to the park exit gate. We’ll meet you there and
          transport you back to the hotel. A small tipping ceremony will be held, and you’ll bid farewell to your guides.
        </p>
        <p class="tips__text"><b>To learn more about tipping on Kilimanjaro, have a look at this video:</b></p>
        <div class="tips__video">
          <iframe
            srcdoc="<style>
            * {
              padding: 0;
              margin: 0;
              overflow: hidden;
            }

            body, html {
              height: 100%;
            }

            a {
              display: flex;
              height: 100%;
            }

            img {
              position: absolute;
              width: 100%;
              height: 100%;
              inset: 0;
            }

            svg {
              margin: auto;
              filter: drop-shadow(1px 1px 10px hsl(206.5, 70.7%, 8%));
              transition: all 0.25s ease-in-out;
            }

            body:hover svg {
              filter: drop-shadow(1px 1px 10px hsl(206.5, 0%, 10%));
              transform: scale(1.2);
            }
          </style>
          <a href='https://www.youtube.com/embed/UOnLl6926Tk?si=Z0F3397Vsu9U0sHB&autoplay=1&mute=1'>
            <img src='https://img.youtube.com/vi/UOnLl6926Tk/maxresdefault.jpg' alt='Preview'>
            <svg xmlns='http://www.w3.org/2000/svg' width='80' height='59' fill='none'><path fill='#fff' d='M21.332 8h36v42.667h-36z'/><path fill='#E60000' d='M78.13 9.985c-1.6-4.923-6.895-7.905-11.767-8.589a249.56 249.56 0 0 0-52.725 0C8.765 2.08 3.456 5.033 1.87 9.985a99.938 99.938 0 0 0 0 38.493c1.6 4.916 6.894 7.905 11.767 8.589a249.568 249.568 0 0 0 52.724 0c4.873-.684 10.182-3.637 11.767-8.59a99.937 99.937 0 0 0 0-38.492Zm-47.7 34.573v-30.66l24.958 15.33c-8.414 5.17-16.58 10.181-24.958 15.33Z'/></svg>
          </a>
        "
            class="tips__iframe"
            loading="lazy"
            src="https://www.youtube.com/embed/UOnLl6926Tk?si=Z0F3397Vsu9U0sHB"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
        </div>
      </section>
      <section class="tips__section tips__section--last" id="section-20">
        <div class="tips__headline">
          <div class="tips__headline-picture">
            <img src="@/assets/img/tips/tips-insurance.svg" alt="Insurance">
          </div>
          <h2 class="tips__title">Evacuation and insurance</h2>
        </div>
        <p class="tips__text">
          At Altezza Travel, safety is at the forefront of our adventure planning, and evacuations are a rarity on our
          trips. With the thousands of hikers we lead annually, fewer than 50 typically require evacuation from
          Kilimanjaro. Evacuations become essential either due to challenges with acclimatization or physical injuries
          sustained during the trek.
        </p>
        <p class="tips__text">
          There are a few methods for descending the mountain in case of an emergency:
        </p>
        <p class="tips__text">
          <b>Walking down:</b> This is the most common method for climbers facing acclimatization issues. If a climber,
          though unable to adjust to a higher altitude, can still walk unaided, our guides will accompany them down the
          mountain.
        </p>
        <p class="tips__text">
          <b>By stretcher:</b> This method is for more severe situations where a climber is immobilized due to advanced
          altitude sickness or significant injuries. It's worth noting that such occurrences are almost non-existent in
          our expeditions. This is because our guides identify early signs of altitude sickness during medical check-ups
          and can adjust the acclimatization process accordingly. However, we are always prepared for anything, and our
          guides are thoroughly trained in the use of stretchers to ensure a swift and safe descent.
        </p>
        <div class="tips__note">
          <div class="tips__note-title">Rescue car (if available)</div>
          <p class="tips__note-text">
            Only on the Marangu route the vehicle evacuation is possible from a higher camp (Horombo). On other routes,
            it’s available only on the last short stretch of the trail. In those cases, an evacuated climber may be
            picked up by a rescue vehicle of the national park rangers to cover the last 30 minutes of walking within a
            10 minute drive. While the guides will do their best to get a car, it may be unavailable because of other
            evacuations or rains.
          </p>
        </div>
        <p class="tips__text">
          <b>Evacuation by helicopter:</b> There are two companies offering helicopter evacuations from Kilimanjaro.
          Altezza Travel works with both. We can swiftly contact them in an emergency to initiate an evacuation.
          However, there are a few essential points to bear in mind regarding helicopter evacuations:
        </p>
        <ul class="tips__list">
          <li>
            <b>Weather Limitations:</b> Adverse weather conditions, such as intense rains, winds, or poor visibility,
            can make it impossible for a helicopter to fly.
          </li>
          <li>
            <b>Priority Situations:</b> Helicopters prioritize life-threatening emergencies, attending to the most
            critical cases first.
          </li>
          <li>
            <b>Altitude Restrictions:</b> Helicopters have landing limitations on Kilimanjaro and typically do not land
            higher than the Barafu or Kibo camps.
          </li>
          <li>
            <b>Capacity Constraints:</b> Helicopters are typically limited to carrying 2-3 climbers, depending on their
            weight. Importantly, they do not transport duffel bags containing gear. Our crew members will deliver them
            once they descend from Kilimanjaro. It may take up to 12 hours from the moment of a helicopter flight.
          </li>
          <li>
            <b>Daytime Restrictions:</b> Due to established aviation regulations, helicopters operate during daytime
            hours only. This practice is a standard worldwide for single-rotor helicopters.
          </li>
        </ul>
        <p class="tips__text">
          Should a climber continue to feel unwell after an evacuation and need medical assistance, we organize a
          transfer to the KCMC hospital, known as one of the better hospitals in the Kilimanjaro region. The medical
          professionals at KCMC are highly experienced in addressing high-altitude-related ailments, ensuring climbers
          receive the best care possible.
        </p>
        <h3 class="tips__title">Insurance</h3>
        <p class="tips__text">
          <b>Insurance is essential to cover medical and helicopter evacuation costs when climbing Kilimanjaro.</b> A
          helicopter evacuation comes with a price tag of over $4,000, which climbers should typically pay immediately
          upon landing*. The helicopter company will furnish all necessary documents to facilitate an insurance claim,
          allowing climbers to seek reimbursement later. It's crucial to make sure that your insurance policy covers
          helicopter evacuations in Tanzania up to an altitude of 6000 meters (19,685 feet).
        </p>
        <p class="tips__text">
          The same principles apply to any charges stemming from hospital visits, extra accommodations, or additional
          transportation that might become necessary because of the evacuation. Selecting a reputable insurance provider
          that comprehensively covers all these potential expenses for your journey is paramount.
        </p>
        <p class="tips__text">
          <b>Our recommended provider is Global Rescue.</b> It isn’t a typical insurance provider - Global Rescue is a
          comprehensive field rescue service. Working in tandem with Altezza Travel, they will coordinate your
          evacuation if necessary. They have a responsive and professional team and, to the best of our knowledge,
          have not had a single failed evacuation on Kilimanjaro.
        </p>
        <p class="tips__text">
          * The best alternative to that and the option that we recommend the most is mountaineering insurance from our
          partners at Global Rescue. Apart from being the most reliable insurance provider we’ve worked with, <b>Global
          Rescue will pay for the helicopter evacuation and other expenses directly,</b> so you will not need to pay
          upon landing. You can purchase Global Rescue membership <a href="https://partner.globalrescue.com/altezzatravel/" target="_blank">here</a>.
        </p>
      </section>
      <div class="tips__footer">
        <h2 class="tips__title">Enjoy your epic Kilimanjaro adventure!</h2>
        <p>If there's anything in this guide that we might have missed, please don't hesitate to contact your manager.</p>
        <div class="tips__footer-img">
          <img src="@/assets/img/tips/tips-footer.jpg" alt="Mount">
        </div>
        <h2 class="tips__title">We can’t wait to welcome you in Tanzania!</h2>
        <p>
          With <img src="@/assets/img/tips/tips-heart.svg" alt="Heart"> from Altezza Travel
        </p>
        <div class="tips__footer-contacts">
          <a href="mailto:office@altezza.travel" class="tips__footer-mail">office@altezza.travel</a>
          <a href="https://altezza.travel/en" class="tips__footer-link">www.altezza.travel</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash'

export default {
  name: 'Tips',
  data() {
    return {
      show_sidebar: false,
    }
  },
  mounted() {
    if (window.innerWidth > 900) {
      window.addEventListener('scroll', debounce(this.onHandleWindowScroll, 10))
    }
  },
  beforeDestroy() {
    if (window.innerWidth > 900) {
      window.removeEventListener('scroll', this.onHandleWindowScroll)
    }
  },
  methods: {
    onHandleWindowScroll() {
      document.querySelector('.tips__nav-list').classList.toggle('tips__nav-list--scroll', window.scrollY > 57)

      document.querySelectorAll('.tips__section').forEach(el => {
        if (el.offsetTop - 300 <= window.scrollY) {
          document.querySelectorAll('.tips__nav-link').forEach(link => {
            link.classList.toggle('tips__nav-link--active', link.getAttribute('href').replace('#', '') === el.id)
          })
        }
      })
    },
    onShowSidebar() {
      this.show_sidebar = !this.show_sidebar
    },
    onScrollToSection(id) {
      window.scrollTo({top: document.getElementById(id).offsetTop - 10, behavior: 'smooth'})
      if (window.innerWidth <= 900) {
        this.show_sidebar = false
      }
    },
  }
}
</script>

<style scoped lang="sass">
.tips__container
  position: relative
  display: flex
  background-image: url("~@/assets/img/tips/tips-bg.jpg")
  background-position: 330px bottom
  background-repeat: no-repeat
  background-size: calc(100% - 330px) 129px

  +max-w($laptop)
    background-position: left bottom
    background-size: 100% 129px

  +max-w($mobile_md)
    background-size: 150% 129px

  +max-w($mobile_sm)
    background-position: 50% bottom
    background-size: 200% 129px

.tips__side
  flex-shrink: 0
  position: absolute
  z-index: 2
  top: 57px
  width: 330px
  padding: 30px 30px 30px 40px
  height: calc(100% - 57px)
  border-right: 1px solid #ddd
  box-shadow: 0 1px 9px rgba(0, 0, 0, 0.06)
  background-color: #fff
  transition: transform 0.3s

  +max-w($laptop_md)
    padding: 30px 15px

  +max-w($laptop)
    top: 0
    height: 100%
    transform: translateX(-100%)

  +max-w($mobile_sm)
    width: 290px

.tips__side--show
  transform: translateX(0)

.tips__nav
  position: sticky
  top: 30px

.tips__side-title
  display: flex
  align-items: center
  gap: 10px
  margin-bottom: 20px
  color: #202020
  font-size: 15px
  font-weight: 600
  line-height: 1
  text-transform: uppercase

  +max-w($laptop_md)
    margin-bottom: 20px

.tips__nav-list
  scrollbar-color: $color-theme transparent
  scrollbar-width: thin
  overflow: hidden auto
  height: calc(100vh - 153px)
  transition: height 0.3s

  +max-w($laptop)
    height: calc(100vh - 96px)

  &::-webkit-scrollbar
    width: 2px

  &::-webkit-scrollbar-track
    border-radius: 5px
    background-color: #f2f3f8

  &::-webkit-scrollbar-thumb
    border-radius: 5px
    background-color: $color-theme

  li
    margin-bottom: 10px

    &:last-child
      margin-bottom: 0

.tips__nav-list--scroll
  height: calc(100vh - 96px)

.tips__nav-icon
  flex-shrink: 0
  display: flex
  align-items: center
  justify-content: center
  width: 25px
  height: 25px
  border-radius: 10px
  background-color: #ececec
  transition: background-color 0.3s ease-out

  img
    filter: invert(81%) sepia(0%) saturate(0%) hue-rotate(152deg) brightness(94%) contrast(85%)
    transition: filter 0.3s

  +max-w($laptop)
    background-color: $color-theme

    img
      filter: invert(100%) sepia(0%) saturate(4%) hue-rotate(126deg) brightness(104%) contrast(101%)

.tips__nav-link
  display: inline-flex
  align-items: center
  gap: 10px
  color: #696969
  font-size: 14px
  transition: color 0.3s

  &:active
    color: #202020

  @media (hover: hover)
    &:hover,
    &:focus-visible
      color: #202020

      .tips__nav-icon
        background-color: $color-theme

        img
          filter: invert(100%) sepia(0%) saturate(4%) hue-rotate(126deg) brightness(104%) contrast(101%)

.tips__nav-link--active
  color: #202020

  .tips__nav-icon
    background-color: $color-theme

    img
      filter: invert(100%) sepia(0%) saturate(4%) hue-rotate(126deg) brightness(104%) contrast(101%)

.tips__content
  flex-grow: 1
  position: relative
  max-width: 1114px
  padding: 83px 60px 150px 390px

  +max-w($laptop_md)
    padding: 80px 15px 120px 345px

  +max-w($laptop)
    padding: 80px 15px 120px 15px

.tips__title
  color: #202020
  font-weight: 600

  &:is(h1)
    font-size: 30px

    +max-w($laptop)
      font-size: 24px

  &:is(h2)
    font-size: 23px

    +max-w($laptop)
      font-size: 18px

  &:is(h3)
    margin-bottom: 20px
    font-size: 20px

    +max-w($laptop)
      font-size: 17px

.tips__title--mb
  margin-bottom: 7px

.tips__title--center
  text-align: center

.tips__head
  display: flex
  align-items: center
  justify-content: space-between
  margin-bottom: 20px
  padding-bottom: 13px
  border-bottom: 1px solid #ececec

  span
    color: #202020
    font-size: 18px
    font-weight: 500

    +max-w($laptop)
      font-size: 16px

.tips__wish
  align-items: center
  margin-bottom: 30px
  padding: 18px 26px
  border-radius: 12px
  color: #202020
  background-color: #f5f5f5
  font-size: 15px
  font-weight: 500
  line-height: 22px

  +max-w($laptop)
    margin-bottom: 20px
    padding: 10px 15px
    font-size: 14px

  img
    margin-bottom: -5px

.tips__section
  margin-bottom: 40px
  padding-bottom: 30px
  border-bottom: 1px solid #ececec

  +max-w($laptop)
    margin-bottom: 20px
    padding-bottom: 20px

.tips__section--last
  border-bottom: none
  margin-bottom: 50px
  padding-bottom: 0

  +max-w($laptop)
    margin-bottom: 30px

.tips__headline
  display: flex
  align-items: center
  gap: 20px
  margin-bottom: 20px

  +max-w($laptop)
    margin-bottom: 15px

.tips__headline-picture
  display: flex
  align-items: center
  width: 37px
  height: 30px
  border-right: 1px solid #cacaca

.tips__text
  margin-bottom: 20px
  color: #000
  font-size: 14px
  line-height: 1.6

  &:last-child
    margin-bottom: 0

  +max-w($laptop)
    margin-bottom: 10px

  b
    color: #000
    font-weight: 500

  i
    font-size: 13px
    font-weight: 300
    font-style: italic

  a
    display: inline-flex
    flex-direction: column
    color: #000
    font-weight: 500

    @media (hover: hover)
      &:hover,
      &:focus-visible
        &:after
          opacity: 0

    &:after
      content: ''
      height: 3px
      border-radius: 3px
      background-color: rgba(119, 176, 23, 0.50)
      opacity: 1
      transform: translateY(-2px)
      transition: opacity 0.3s

.tips__note
  margin-bottom: 25px
  padding: 24px 30px
  border-left: 3px solid #77b017
  border-radius: 0 10px 10px 0
  box-shadow: 0 1px 9px rgba(0, 0, 0, 0.06)
  background-color: #f2f4ee

  &:last-child
    margin-bottom: 0

  +max-w($laptop)
    margin-bottom: 10px
    padding: 10px 15px

.tips__note-title
  margin-bottom: 15px
  color: #191f0f
  font-size: 14px
  font-weight: 500

  +max-w($laptop)
    margin-bottom: 8px

.tips__note-text
  margin-bottom: 15px
  color: #191f0f
  font-size: 14px
  line-height: 1.6

  &:last-child
    margin-bottom: 0

  b
    color: #191f0f
    font-weight: 500

  a
    display: inline-flex
    flex-direction: column
    color: #191f0f
    font-weight: 500

    @media (hover: hover)
      &:hover,
      &:focus-visible
        &:after
          opacity: 0

    &:after
      content: ''
      height: 3px
      border-radius: 3px
      background-color: rgba(119, 176, 23, 0.50)
      opacity: 1
      transform: translateY(-2px)
      transition: opacity 0.3s

.tips__list
  margin-bottom: 25px
  padding-left: 22px

  &:is(ul)
    list-style-type: disc

  &:is(ol)
    list-style-type: decimal

  &:last-child
    margin-bottom: 0

  li
    margin-bottom: 5px
    color: #000
    font-size: 14px
    line-height: 1.6

    &:last-child
      margin-bottom: 0

    b
      color: #000
      font-weight: 500

.tips__subt
  display: flex
  align-items: center
  gap: 7px
  margin-bottom: 10px
  color: #202020
  font-size: 14px
  line-height: 1.7
  font-weight: 600
  text-transform: uppercase

.tips__underline
  text-decoration: underline

.tips__burger
  position: fixed
  right: 15px
  width: 46px
  height: 46px
  border-radius: 50%
  background-color: #f5f5f5
  background-image: url("~@/assets/img/tips/tips-burger.svg")
  background-position: center
  background-repeat: no-repeat
  z-index: 2

  +min-w($laptop)
    display: none

.tips__video
  position: relative
  margin-bottom: 30px
  aspect-ratio: 1.77
  border-radius: 10px
  overflow: hidden

  &:last-child
    margin-bottom: 0

.tips__thumbnail
    position: absolute
    inset: 0
    width: 100%
    height: 100%
    z-index: 10
    object-fit: cover

.tips__iframe
  position: absolute
  inset: 0
  width: 100%
  height: 100%
  z-index: 1

.tips__gear
  display: flex
  align-items: flex-end
  gap: 24px
  margin-bottom: 30px
  padding: 25px
  border-radius: 14px
  background-image: linear-gradient(212deg, #163950 37.58%, #347196 117.08%)
  box-shadow: 0 1px 9px 0 rgba(0, 0, 0, 0.10)

  +max-w($mobile_md)
    flex-direction: column
    align-items: flex-start
    gap: 15px
    padding: 15px

.tips__gear-img
  position: relative
  flex-shrink: 0
  width: 177px
  height: 200px
  background-image: url("~@/assets/img/tips/tips-gear-man.png")
  background-size: cover
  background-repeat: no-repeat

  &:before
    content: ''
    position: absolute
    background-image: url("~@/assets/img/tips/tips-gear-packing.svg")
    background-size: cover
    left: -11px
    bottom: 0
    width: 138px
    height: 44px

.tips__gear-content
  display: flex
  flex-direction: column

.tips__gear-title
  margin-bottom: 12px
  color: #fff
  font-size: 23px
  font-weight: 600
  line-height: 1.2
  letter-spacing: -0.46px
  text-transform: capitalize

  +max-w($mobile_md)
    margin-bottom: 10px
    font-size: 18px

.tips__gear-text
  margin-bottom: 28px
  color: #fff
  font-size: 14px
  line-height: 1.5

  +max-w($mobile_md)
    margin-bottom: 15px

.tips__gear-link
  display: flex
  align-items: center
  justify-content: center
  width: 230px
  height: 48px
  border-radius: 10px
  color: #fff
  background-color: #68a522
  font-size: 14px
  font-weight: 600
  background-image: url("~@/assets/img/tips/tips-gear-download.svg")
  background-size: 16px
  background-repeat: no-repeat
  background-position: 55px center
  transition: background-color 0.3s

  @media (hover: hover)
    &:hover,
    &:focus-visible
      background-color: $color-theme

.tips__footer
  text-align: center

  h2
    margin-bottom: 15px
    color: #202020

  p
    margin-bottom: 30px
    color: #202020
    font-size: 15px
    font-weight: 500

    +max-w($laptop)
      margin-bottom: 20px

    img
      margin: 0 5px -7px

.tips__footer-img
  position: relative
  width: 100%
  margin-bottom: 30px
  aspect-ratio: 3.161
  border-radius: 10px
  overflow: hidden

  +max-w($laptop)
    margin-bottom: 20px

  img
    position: absolute
    inset: 0
    width: 100%
    height: 100%
    object-fit: cover

.tips__footer-contacts
  display: flex
  flex-direction: column
  align-items: center
  gap: 15px

  a
    font-size: 15px
    color: $color-theme
    transition: color 0.3s, border-color 0.3s

.tips__footer-mail
  @media (hover: hover)
    &:hover,
    &:focus-visible
      color: #68a522

.tips__footer-link
  border-bottom: 1px solid $color-theme

  @media (hover: hover)
    &:hover,
    &:focus-visible
      border-color: transparent
</style>
